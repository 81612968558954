import { Reactable, combine } from "@reactables/core";
import { from, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { HookedReactable } from "@reactables/react";
import { ControlModels } from "@reactables/forms";
import {
  RequestState,
  RequestActions,
  RxEntity,
  RxBuilder,
} from "@jauntin/reactables";

import MembershipService from "../../../Services/MembershipService";
import FacilityService from "Services/FacilityService";

import { CancelMemberPayload, SaveMemberPayload } from "./Models/member.model";
import {
  getContactsAndEmailPayload,
  MemberContactsForm,
} from "./Models/memberContactsForm.model";
import { MemberDetails } from "./Models/memberDetails.model";

export interface MemberState {
  entity: RequestState<MemberDetails>;
  saveMember: RequestState<unknown>;
  cancelMember: RequestState<unknown>;
  memberEmails: RequestState<unknown>;
  paymentMethodUpdateLink: RequestState<{ success: boolean }>;
  addNote: RequestState<unknown>;
  deleteContact: RequestState<unknown>;
  deletingContactId: number;
}

export type MemberActions = {
  saveMember: RequestActions<SaveMemberPayload>;
  cancelMember: RequestActions<CancelMemberPayload>;
  memberEmails: RequestActions<{
    form: ControlModels.Form<MemberContactsForm>;
    memberDetails: MemberDetails;
  }>;
  paymentMethodUpdateLink: RequestActions<undefined>;
  addNote: RequestActions<{ id: number; message: string }>;
  deleteContact: RequestActions<{ contactId: number }>;
};

export type RxMemberProp = HookedReactable<MemberState, MemberActions>;

export const RxMember = ({
  membershipService,
  id,
  onSaveMemberSuccess,
}: {
  membershipService: MembershipService;
  facilityService: FacilityService;
  id: number;
  onSaveMemberSuccess: () => void;
}) => {
  const rxMemberEntity = RxEntity({
    name: "Member",
    getEntityResource: () =>
      membershipService
        .getMembership(id)
        .then(({ data }) => data) as Promise<MemberDetails>,
    entityActions: {
      saveMember: {
        resource: (payload: SaveMemberPayload) =>
          membershipService.saveMember(payload, id),
        onSuccess: onSaveMemberSuccess,
      },
      cancelMember: {
        resource: (payload: CancelMemberPayload) =>
          membershipService.cancelMember(payload, id),
      },
      memberEmails: {
        resource: (params: {
          form: ControlModels.Form<MemberContactsForm>;
          memberDetails: MemberDetails;
        }) => {
          const { emails, customContacts } = getContactsAndEmailPayload(params);

          return forkJoin([
            from(membershipService.updateCustomContacts(id, customContacts)),
            from(membershipService.sendWelcomeEmails(emails, id)),
          ]).pipe(map(([{ data: updatedContacts }]) => updatedContacts));
        },
      },
      addNote: {
        resource: ({ message }: { message: string }) =>
          membershipService.addNote({ id, message }),
      },
      paymentMethodUpdateLink: {
        resource: () => membershipService.sendUpdateLink(id),
      },
      deleteContact: {
        resource: ({ contactId }: { contactId: number }) =>
          membershipService
            .deleteContact({
              id,
              contactId,
            })
            .then(() => ({ contactId })),
      },
    },
  });

  const [, , memberEntityActions$] = rxMemberEntity;

  const rxDeletingContactId = RxBuilder({
    initialState: null,
    sources: [memberEntityActions$],
    reducers: {
      "Member - deleteContact - send": (_, { payload }) =>
        (payload as { contactId: number }).contactId,
      "Member - deleteContact - sendSuccess": () => null,
      "Member - deleteContact - sendFailure": () => null,
    },
  });

  const [state$, actions] = combine({
    memberEntity: rxMemberEntity,
    deletingContactId: rxDeletingContactId,
  });

  const formattedState$ = state$.pipe(
    map(({ memberEntity, deletingContactId }) => ({
      ...memberEntity,
      deletingContactId,
    }))
  );

  return [
    formattedState$,
    actions.memberEntity,
    memberEntityActions$,
  ] as Reactable<MemberState, MemberActions>;
};
