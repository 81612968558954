import { Observable } from "rxjs";
import { FormBuilders } from "@jauntin/reactables";
import { MemberContactsForm } from "./Models/memberContactsForm.model";
import { Action, Reactable } from "@reactables/core";
import formProviders from "@basicare/common/src/Helpers/formProviders";
import { ControlModels, RxFormActions, CustomReducer } from "@reactables/forms";
import { CustomContact, MemberDetails } from "./Models/memberDetails.model";
import { memberContacts } from "./Configs/memberContacts.config";

export type MemberContactsFormState = ControlModels.Form<MemberContactsForm>;
export type MemberContactsFormActions = {
  selectAllEmails: () => void;
  deselectAllEmails: () => void;
} & RxFormActions;

export const getMembershipEmails = (memberDetails: MemberDetails) => {
  const {
    email,
    organization: { contacts, facilityProducerContacts },
  } = memberDetails;

  return [
    email,
    ...contacts.map(({ email }) => email),
    ...facilityProducerContacts.map(({ email }) => email),
  ];
};

const updateAllCheckboxes =
  (selectAll: boolean): CustomReducer =>
  (
    { updateValues },
    state: ControlModels.BaseFormState<MemberContactsForm>
  ) => {
    const {
      customContacts,
      emailOrganizationContacts,
      emailOrganizationProducerContacts,
    } = state.form.root.value;

    const value: MemberContactsForm = {
      emailPrimaryMember: selectAll,
      emailOrganizationContacts: emailOrganizationContacts.map(() => selectAll),
      emailOrganizationProducerContacts: emailOrganizationProducerContacts.map(
        () => selectAll
      ),
      customContacts: customContacts.map(({ contactInfo }) => ({
        sendEmail: selectAll,
        contactInfo,
      })),
    };

    return updateValues(state, { controlRef: [], value });
  };

export const RxMemberContactsForm = ({
  memberDetails,
  sources,
}: {
  memberDetails: MemberDetails;
  sources: Observable<Action<unknown>>[];
}) =>
  FormBuilders.build(memberContacts(memberDetails), {
    sources,
    reducers: {
      selectAllEmails: updateAllCheckboxes(true),
      deselectAllEmails: updateAllCheckboxes(false),
      "Member - memberEmails - sendSuccess": (
        { updateValues },
        state: ControlModels.BaseFormState<MemberContactsForm>,
        { payload: updatedContacts }: Action<CustomContact[]>
      ) => {
        const { value } = state.form.root;
        const newValue: MemberContactsForm = {
          ...value,
          customContacts: value.customContacts.map((contact, index) => ({
            sendEmail: contact.sendEmail,
            contactInfo: updatedContacts[index],
          })),
        };
        state = updateValues(state, { controlRef: [], value: newValue });
        return state;
      },
      deletedContactSuccess: (
        { removeControl },
        state: ControlModels.BaseFormState<MemberContactsForm>,
        { payload: { contactId } }: Action<{ contactId: number }>
      ) => {
        const i = state.form.root.value.customContacts.findIndex(
          (v) => v.contactInfo.id === contactId
        );

        return removeControl(state, ["customContacts", i]);
      },
    },
    providers: {
      ...formProviders,
      validators: {
        ...formProviders.validators,
        emailSelected: ({
          emailPrimaryMember,
          emailOrganizationContacts,
          emailOrganizationProducerContacts,
          customContacts,
        }: MemberContactsForm) => {
          return {
            emailSelected: ![
              emailPrimaryMember,
              ...emailOrganizationContacts,
              ...emailOrganizationProducerContacts,
              ...customContacts.map(({ sendEmail }) => sendEmail),
            ].some((selected) => selected),
          };
        },
        uniqueCustomContactEmails: ({ customContacts }: MemberContactsForm) => {
          const membershipEmails = new Set(getMembershipEmails(memberDetails));

          const allEmails = [
            ...membershipEmails,
            ...customContacts.map(({ contactInfo: { email } }) => email),
          ].filter((email) => Boolean(email));

          return {
            uniqueCustomContactEmails:
              new Set(allEmails).size !== allEmails.length,
          };
        },
      },
    },
  }) as Reactable<MemberContactsFormState, MemberContactsFormActions>;
